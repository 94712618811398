import React from 'react'

import './Home.scss'
import Carousel from './Carousel/Carousel'

function Home() {



  return(
    <div className="home">
      <div className="home__container">
        <div className="content__container">
          <div className="content__block">
            <Carousel/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;
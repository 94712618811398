import React from 'react'

import './Osaka.scss'

function Osaka() {



  return(
    <div className="osaka">
      Osaka
    </div>
  )
}

export default Osaka;
import React from 'react'

import './Card.scss'
import tokyo from '../../../../../../common/images/cities/tokyo.jpg'

function Card(props) {

  const images = require.context('../../../../../../common/images/cities', true);

  const imageStyle = {
    backgroundImage: `url(${images(`./${props.data.title.toLowerCase()}.jpg`)})`
  }

  return(
    <a className="card" href={`/${props.data.title.toLowerCase()}`}>
      <div className="card__image" style={imageStyle}></div>
      <div className={`card__title ${props.data.title.length > 8 ? 'card__title--long' : 'card__title--short' }`}>{props.data.title}</div>
    </a>
  )
}

export default Card;
import React, {useState, useEffect, useRef} from 'react'

import './Carousel.scss'
import Cards from './Cards/Cards'

const MARGINS = 2;
const WIDTH = 500 + MARGINS;

function Carousel() {

  const childRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [oldWidth, setOldWidth] = useState(0);
  const [dataSize, setDataSize] = useState(0);
  const [leftMargin, setLeftMargin] = useState(false);

  const updateChildData = (dataSize) => {
    setDataSize(dataSize);
  }

  function handleResize() {
    setWidth(childRef.current.offsetWidth);
  }

  useEffect(() => {

    setWidth(childRef.current.offsetWidth);
    window.addEventListener('resize', handleResize)

    if(leftMargin) {
      if(oldWidth < width) {
        setActiveIndex(dataSize*WIDTH - width);
      } else {
        setLeftMargin(false);
      }
    }

    if(activeIndex + width > dataSize*WIDTH && activeIndex !== 0) {
      if(oldWidth < width) {
        setActiveIndex(dataSize*WIDTH - width);
        setLeftMargin(true);
      } else {
        setLeftMargin(false);
      }
    }

    setOldWidth(width);

    return _ => {
      window.removeEventListener('resize', handleResize)
    }

  }, [width, activeIndex, dataSize, leftMargin, oldWidth])

  function onNextClick(){
    setWidth(childRef.current.offsetWidth);
    const viewPort = Math.floor(width/WIDTH);
    if(activeIndex + viewPort*WIDTH >= dataSize*WIDTH - viewPort*WIDTH) {
      setActiveIndex(dataSize*WIDTH - width);
      setLeftMargin(true);
    } else {
      setActiveIndex(activeIndex + viewPort*WIDTH);
    }
  }

  function onPreviousClick() {
    setWidth(childRef.current.offsetWidth);
    const viewPort = Math.floor(width/WIDTH);
    setActiveIndex(activeIndex - viewPort*WIDTH <= 0 ? 0 : activeIndex - viewPort*WIDTH);
    if(leftMargin) { 
      setLeftMargin(false);
    }
  }

  const sliderStyle = {
    transform:`translateX(${-(activeIndex)}px)`,
    transition: `1s`,
  }

  return(
    <div className="carousel">
      <div className="carousel__viewport" ref={childRef}>
        <div className="carousel__tab" style={sliderStyle}>
          <Cards triggerParentUpdate={updateChildData}/>
        </div>
      </div>

      <a href="#/" className={`carousel__button${activeIndex <= '0' ? '--inactive' : '--previous'}`} 
          onClick={onPreviousClick}>
        <i className="car-icon"></i>
      </a>

      <a href="#/" className={`carousel__button${activeIndex >= WIDTH * dataSize - width ? '--inactive' : '--next'}`} 
          onClick={onNextClick}>
        <i className="car-icon"></i>
      </a>

    </div>
  )
}

export default Carousel;
import React, {useEffect} from 'react'

import Card from './Card/Card'

function Cards(props) {

  const data = [
    {
      title: "Tokyo",
      id: 1
    },
    {
      title: "Kyoto",
      id: 2
    },
    {
      title: "Osaka",
      id: 3
    },
    {
      title: "Hiroshima",
      id: 4
    },
  ]

  function updateDataSize() {
    props.triggerParentUpdate(data.length);
  }

  useEffect(updateDataSize, []);

  const cards = [];
  data.forEach(card => {
    cards.push(<Card data={card} key={"card " + card.id}/>)
  })

  return(
    <div style={{display: 'flex'}}>{cards}</div>
  )
}

export default Cards;
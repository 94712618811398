import React from 'react'

import './Tokyo.scss'

function Tokyo() {



  return(
    <div className="tokyo">
      Tokyo
    </div>
  )
}

export default Tokyo;
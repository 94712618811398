import React from 'react'

import './Footer.scss'

function Footer() {



  return(
    <div className="footer">
      
    </div>
  )
}

export default Footer;
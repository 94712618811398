import React from 'react'

import './Kyoto.scss'

function Kyoto() {



  return(
    <div className="kyoto">
      Kyoto
    </div>
  )
}

export default Kyoto;
import React from 'react';

import './App.scss';
import Header from './Header/Header'
import Content from './Content/Content'
import Footer from './Footer/Footer'

function App() {
  return (
    <>
      <Header/>
      <Content/>
      <Footer/>
    </>
  );
}

export default App;

import React from 'react'

import './Hiroshima.scss'

function Hiroshima() {



  return(
    <div className="hiroshima">
      Hiroshima
    </div>
  )
}

export default Hiroshima;
import React from 'react'

import './Header.scss'

function Header() {



  return(
    <div className="navbar">
      <div className="navbar__container">
        <div className="navbar__element navbar__logo">
          <a className="logo" href="/">
            <span className="logo__first-span">japan</span>
            <span className="logo__second-span">trip</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header;
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './Content.scss'
import Home from './Home/Home'
import Tokyo from './Tokyo/Tokyo'
import Kyoto from './Kyoto/Kyoto'
import Osaka from './Osaka/Osaka'
import Hiroshima from './Hiroshima/Hiroshima'

function Content() {



  return(
    <Router>
      <Switch>
        <Route exact path='/' render={() => <Home/>}/>
        <Route exact path='/tokyo' render={() => <Tokyo/>}/>
        <Route exact path='/kyoto' render={() => <Kyoto/>}/>
        <Route exact path='/osaka' render={() => <Osaka/>}/>
        <Route exact path='/hiroshima' render={() => <Hiroshima/>}/>
      </Switch>
    </Router>
  )
}

export default Content;